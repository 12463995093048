import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"400"},model:{value:(_vm.showingModal),callback:function ($$v) {_vm.showingModal=$$v},expression:"showingModal"}},[_c(VCard,[_c('div',{staticClass:"pa-5"},[(_vm.batch.vcf_contacts_file)?_c(VRow,{staticClass:"align-center"},[_c(VCol,{attrs:{"cols":"8"}},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v("Archivo VCF con contactos")])]),_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"4"}},[_c(VBtn,{attrs:{"disabled":_vm.batch.vcf_contacts_file.status !== 1,"icon":""},on:{"click":function($event){return _vm.downloadFile(_vm.batch.vcf_contacts_file.vcffile)}}},[_c(VIcon,{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDownloadOutline)+" ")])],1)],1)],1):_vm._e(),_c(VRow,{staticClass:"align-center"},[_c(VCol,{attrs:{"cols":"8"}},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v("Archivo de creación de lote")])]),_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"4"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.downloadFile(_vm.batch.file)}}},[_c(VIcon,{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDownloadOutline)+" ")])],1)],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CERRAR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }