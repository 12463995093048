<template>
  <v-dialog v-model="showingModal" width="400">
    <v-card >
      <div class="pa-5">
        <v-row v-if="batch.vcf_contacts_file" class="align-center">
          <v-col cols="8">
            <span class="font-weight-semibold">Archivo VCF con contactos</span>
          </v-col>
          <v-col cols="4" class="d-flex justify-center">
            <v-btn :disabled="batch.vcf_contacts_file.status !== 1" icon @click="downloadFile(batch.vcf_contacts_file.vcffile)">
              <v-icon class="success--text">
                {{ icons.mdiFileDownloadOutline }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
  
        <v-row class="align-center">
          <v-col cols="8">
            <span class="font-weight-semibold">Archivo de creación de lote</span>
          </v-col>
          <v-col cols="4" class="d-flex justify-center">
            <v-btn icon @click="downloadFile(batch.file)">
              <v-icon class="success--text">
                {{ icons.mdiFileDownloadOutline }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()">
          CERRAR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiFileDownloadOutline
} from '@mdi/js'

export default {
  data: () => {
    return {
      showingModal: false,
      batch: {}
    }
  },
  methods: {
    openModal(item) {
      this.batch = item
      this.showingModal = true
    },

    close() {
      this.batch = {}
      this.showingModal = false
    },

    async downloadFile(url) {
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.click();
    }
  },
  setup() {
    return {
      icons: {
        mdiFileDownloadOutline
      }
    }
  }
}
</script>