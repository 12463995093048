<template>
  <div>
    <v-card>
      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- Search -->
        <v-text-field
          v-model="searchQuery"
          @keyup="handleSearch"
          placeholder="Buscar por nombre de contacto"
          outlined
          hide-details
          dense
          class="contact-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>
        <v-spacer v-if="parentElement === 'batches'"></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn v-if="parentElement === 'batches' && batch.file"
            color="primary"
            class="mb-3 me-6"
            @click="$refs.batch_vcf_file_modal.openModal(batch)"
          >
            Ver archivos
          </v-btn>
          <v-btn v-if="parentElement !== 'batches'"
            color="primary"
            class="mb-4 me-3 px-2 no-uppercase"
            @click="$refs.contact_form_modal.openModal()"
          >
            <v-icon>{{ icons.mdiAccountPlus }}</v-icon>
            <span>Nuevo Contacto</span>
          </v-btn>

          <v-btn v-if="parentElement !== 'batches'"
            color="info"
            class="mb-4 me-3 px-2 no-uppercase"
            @click="exportContacts()"
          >
            <v-icon>{{ icons.mdiFileDownload }}</v-icon>
            <span>Exportar CSV</span>
          </v-btn>

          <v-btn
            v-if="parentElement !== 'batches' && contactsAvailableExport"
            color="success"
            class="mb-4 me-3 px-2 no-uppercase"
            @click="downloadContacts()"
          >
            <v-icon>{{ icons.mdiDownload }}</v-icon>
            <span>Descargar Archivo</span>
          </v-btn>
        </div>
      </v-card-text>

      <v-data-table
        :headers="tableColumns"
        :items="contactsListTable"
        :page="current_page"
        :server-items-length="totalContactsListTable"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
        @update:page="changingContactsPage($event)"
        @update:items-per-page="changingContactsItemsPerPage($event)"
      >
        <!-- NO data -->
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              No hay
              <strong>Contactos</strong>
              disponibles para esta búsqueda
            </v-list-item-title>
          </v-list-item>
        </template>

        <!-- Name -->
        <template #[`item.name`]="{ item }">
          <v-btn
            class="no-uppercase"
            text
            @click="$refs.contacts_details_modal.open(item)"
          >
            {{ item.name }}
          </v-btn>
        </template>
        <!-- Phone -->

        <!--Tag -->
        <template #[`item.tags`]="{ item }">
          <v-btn
            class="no-uppercase tag-container my-1"
            text
            max-height="26px"
            v-for="tag in item.tags"
          >
            <v-icon class="mr-1" dense small>{{ icons.mdiTag }}</v-icon>
            <span>{{ tag.name }}</span>
          </v-btn>
        </template>

        <template #[`item.google_sync`]="{ item }">
          <v-icon v-if="item.google_sync" icon color="success">
            {{ icons.mdiCheckCircleOutline }}
          </v-icon>

          <v-icon v-else icon color="error">
            {{ icons.mdiCloseCircleOutline }}
          </v-icon>
        </template>

        <!-- Edit -->

        <template #[`item.edit`]="{ item }">
          <v-btn icon color="primary" @click="$refs.contact_form_modal.openModal(item)">
            <v-icon>
              {{ icons.mdiSquareEditOutline }}
            </v-icon>
          </v-btn>
        </template>

        <!-- Delete -->
        <template #[`item.delete`]="{ item }">
          <v-btn icon @click="$refs.delete_contact_modal.open(item)">
            <v-icon class="error--text">
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <DeleteContactModal
        ref="delete_contact_modal"
        @reload-contact-list="getContacts()"
      />

      <ContactFormModal
        ref="contact_form_modal"
        @reload-contact-list="getContacts()"
      />

      <BatchVcfFileModal
        ref="batch_vcf_file_modal"
      />

      <ContactsDetailsModal ref="contacts_details_modal" />

      <alert-pop-up ref="alert_pop_up" />
    </v-card>
  </div>
</template>
<script>
const debounce = require("debounce");
import { ref } from "@vue/composition-api";

import DeleteContactModal from "@/components/Contacts/DeleteContactModal";
import ContactFormModal from "@/components/Contacts/ContactFormModal";
import ContactsDetailsModal from "@/components/Contacts/ContactsDetailsModal";
import BatchVcfFileModal from "@/components/ContactBatches/BatchVcfFileModal"
import AlertPopUp from "@/components/AlertPopUp.vue";

import {
  mdiAccountPlus,
  mdiFileDownload,
  mdiFileUploadOutline,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiTag,
  mdiDownload,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline
} from "@mdi/js";

export default {
  data: () => {
    return {
      current_page: 1,
      page_size: 10,
      companyTagsData: [],
      contactsAvailableExport: false,
      timer: null,
      totalContactsListTable: 0,
      contactsListTable: []
    };
  },
  components: {
    DeleteContactModal,
    ContactFormModal,
    ContactsDetailsModal,
    BatchVcfFileModal,
    AlertPopUp,
  },
  props: {
    parentElement: {
      type: String,
      default: () => '',
    },
    batch: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    if (this.getSelectedCompany  && this.parentElement === "contacts") {
      this.getContacts();
    }
  },
  methods: {
    handleSearch: debounce(function (e) {
      this.current_page = 1;
      this.getContacts();
    }, 400),

    changingContactsPage(e) {
      this.current_page = e;
      this.getContacts();
    },

    changingContactsItemsPerPage(e) {
      this.page_size = e;
      this.getContacts();
    },

    async exportContacts() {
      const response = await this.$api.exportContacts(this.getSelectedCompany);
      if (response.message) {
        this.$refs.alert_pop_up.showPopUp({ message: response.message, type: "success" });
      }
      this.getContacts();
    },

    async downloadContacts() {
      try {
        const data = await this.$api.downloadContacts(this.getSelectedCompany);
        this.clickCreatedLink(data);
      } catch (e) {
        if (e?.response?.status === 403) {
          this.$refs.alert_pop_up.showPopUp({
            message: e?.response?.data?.message,
            type: "error",
          });
        }
      }
    },

    async exportCsvSample() {
      const sample = true;
      const data = await this.$api.exportCsvSample();
      this.clickCreatedLink(data, sample);
    },

    clickCreatedLink(data, sample) {
      const blob = new Blob([data], { type: "text/html;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        sample ? "Csv Ejemplo.csv" : `Contactos_Empresa_id_${this.getSelectedCompany}.xls`
      );
      link.click();
    },

    async getContacts() {
      this.loading = true;
      try {
        const query = {
          company_id: this.getSelectedCompany,
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery,
          // batch_id: 4,
        };

        if(this.parentElement === 'batches') query.batch_id = this.batch.id

        const response = await this.$api.getContacts(query);
        this.contactsListTable = response.results;
        this.totalContactsListTable = response.count;

        const availableExportsResponse = await this.$api.contactsCheckAvailableExports(
          this.getSelectedCompany
        );
        this.contactsAvailableExport = availableExportsResponse.available;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.setTimer();
      }
    },

    setTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.getContacts();
      }, 1000 * 60);
    },
    clearContactList(){
      this.contactsListTable = []
      this.totalContactsListTable = 0
    }
  },
  computed: {
    getUserRole() {
      return this.$store.getters["session/getUserRole"]();
    },
    getSelectedCompany() {
      return this.$store.getters["session/getSelectedCompany"]();
    },
    tableColumns() {
      let tableColumns = [
        { text: "Nombre", value: "name", align: "center", sortable: false },
        { text: "Teléfono", value: "full_number", align: "center", sortable: false },
        { text: "Tags", value: "tags", align: "center", sortable: false },
        { text: "DNI", value: "dni", align: "center", sortable: false },
        { text: "Sincronizado con Google", value: "google_sync", align: "center", sortable: false },
        { text: "Editar", value: "edit", align: "center", sortable: false },
        { text: "Eliminar", value: "delete", align: "center", sortable: false },
      ]
      if (this.parentElement === 'batches') {
        tableColumns.splice(tableColumns.length - 2, 2); // Eliminar ultimos dos elementos
      }
      return tableColumns;
    },
  },

  watch: {
    getSelectedCompany(newValue) {
      if (newValue) {
        this.current_page = 1;
        this.page_size = 10;
        this.getContacts();
      }
    },
    batch(newValue) {
      if(newValue.id) {
        this.getContacts();
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  setup() {
    const loading = ref(false);
    const searchQuery = ref("");

    return {
      // tableColumns,
      loading,
      searchQuery,
      // totalContactsListTable,
      // contactsListTable,
      icons: {
        mdiAccountPlus,
        mdiFileDownload,
        mdiFileUploadOutline,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiTag,
        mdiDownload,
        mdiCheckCircleOutline,
        mdiCloseCircleOutline
      },
    };
  },
};
</script>

<style scoped>
.no-uppercase {
  text-transform: unset !important;
}

.tag-container {
  color: white;
  background-color: rgb(120, 120, 120);
  font-size: 14px;
}

.tag-container:hover {
  cursor: default;
}
</style>
