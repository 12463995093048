<template>
    <v-dialog
        v-model="showingDeleteContactModal"
        width="auto"
    >
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                ¿Estás seguro de eliminar el contacto '{{ contact.name }}'?
            </v-card-title>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                color="primary" 
                text 
                @click="close()"
                >
                    CANCELAR
                </v-btn>         
                <v-btn
                color="error"
                @click="deleteContact()"
                >
                    Eliminar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    data: () => {
        return {
            showingDeleteContactModal: false,
            contact: {}
        }
    },

    methods: {
        open(contact) {
            this.showingDeleteContactModal = true
            this.contact = contact
        },

        close() {
            this.showingDeleteContactModal = false
        },

        async deleteContact() {
            try {
                const response = await this.$api.deleteContact(this.contact.id)

                this.$emit('reload-contact-list')
                this.close()
            } catch(e) {
                console.log(e)
            }
        }
    }
}
</script>